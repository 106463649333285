import { setDisclaimerAPI } from 'api';
import { AxiosError } from 'axios';
import { Button, ButtonVariant, Checkbox, HeadingS, HorizontalFlex, Modal, RegularTextS, Space, ToastContext, VerticalFlex } from 'dap-ui-components';
import { setDisclaimerSubmit } from 'helpers/authHelper';
import { useTranslate } from 'i18n';
import { FC, useContext, useState } from 'react';
import { selectEnv, selectUser } from 'store/core/slice';
import { useAppSelector } from 'store/types';
import { AppDisclaimerScroller } from './AppDisclaimer.style';

export interface AppDisclaimerProps {
  onClose: () => void;
  reloadData: () => Promise<void>;
}

const AppDisclaimer: FC<AppDisclaimerProps> = ({
  onClose,
  reloadData,
}) => {
  const t = useTranslate();
  const [isChecked, setChecked] = useState(false);
  const env = useAppSelector(selectEnv);
  const user = useAppSelector(selectUser);
  const { showProgressToast, showErrorToast, hideToast } = useContext(ToastContext);

  const handleSubmit = async() => {
    try {
      showProgressToast(t('common.progress'));
      await setDisclaimerAPI(env.uamDisclaimerEndpoint);
      setDisclaimerSubmit(user.email);
      onClose();
      reloadData();
      hideToast();
    } catch (error) {
      const errorResponse = (error as AxiosError)?.response;

      if (errorResponse?.status === 409) {
        // The user has already submitted the form.
        setDisclaimerSubmit(user.email);
        onClose();
        reloadData();
        hideToast();
      } else {
        const errorMessage = t(`apiErrors.${errorResponse?.status}`, t('common.genericError'));
        showErrorToast(errorMessage);
      }
    }
  };

  return (
    <Modal
      title={t('disclaimer.title')}
      body={
        <VerticalFlex $gap={Space.V8}>
          <AppDisclaimerScroller>
            <VerticalFlex $gap={Space.V24}>
              <VerticalFlex $gap={Space.V8}>
                <HeadingS>{t('disclaimer.subtitle')}</HeadingS>
                <RegularTextS>{t('disclaimer.byUser')}</RegularTextS>
                <RegularTextS>{t('disclaimer.byUserCompany')}</RegularTextS>
                <RegularTextS>{t('disclaimer.byEuronext')}</RegularTextS>
              </VerticalFlex>

              <VerticalFlex $gap={Space.V8}>
                <HeadingS>{t('disclaimer.terms.0.title')}</HeadingS>
                <RegularTextS>{t('disclaimer.terms.0.sub.0.text')}</RegularTextS>
                <RegularTextS>{t('disclaimer.terms.0.sub.1.text')}</RegularTextS>
              </VerticalFlex>

              <VerticalFlex $gap={Space.V8}>
                <HeadingS>{t('disclaimer.terms.1.title')}</HeadingS>
                <RegularTextS>{t('disclaimer.terms.1.text')}</RegularTextS>
              </VerticalFlex>

              <VerticalFlex $gap={Space.V8}>
                <HeadingS>{t('disclaimer.terms.2.title')}</HeadingS>
                <RegularTextS>{t('disclaimer.terms.2.text')}</RegularTextS>
              </VerticalFlex>

              <VerticalFlex $gap={Space.V8}>
                <HeadingS>{t('disclaimer.terms.3.title')}</HeadingS>
                <RegularTextS>{t('disclaimer.terms.3.sub.0.text')}</RegularTextS>

                <VerticalFlex $gap={Space.V4}>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.0.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.1.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.2.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.3.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.4.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.5.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.6.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.7.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.8.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.9.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.10.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.11.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.12.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.13.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.14.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.15.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.16.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.1.sub.17.text')}</RegularTextS>
                </VerticalFlex>

                <VerticalFlex $gap={Space.V4}>
                  <RegularTextS>{t('disclaimer.terms.3.sub.2.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.2.sub.0.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.2.sub.1.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.2.sub.2.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.2.sub.3.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.2.sub.4.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.2.sub.5.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.2.sub.6.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.2.sub.7.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.2.sub.8.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.2.sub.9.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.2.sub.10.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.2.sub.11.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.2.sub.12.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.2.sub.13.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.2.sub.14.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.3.sub.2.sub.15.text')}</RegularTextS>
                </VerticalFlex>

                <RegularTextS>{t('disclaimer.terms.3.sub.3.text')}</RegularTextS>
              </VerticalFlex>

              <VerticalFlex $gap={Space.V8}>
                <HeadingS>{t('disclaimer.terms.4.title')}</HeadingS>

                <VerticalFlex $gap={Space.V4}>
                  <RegularTextS>{t('disclaimer.terms.4.sub.0.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.4.sub.0.sub.0.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.4.sub.0.sub.1.text')}</RegularTextS>
                  <RegularTextS>{t('disclaimer.terms.4.sub.0.sub.2.text')}</RegularTextS>
                </VerticalFlex>

                <RegularTextS>{t('disclaimer.terms.4.sub.1.text')}</RegularTextS>
              </VerticalFlex>

              <HeadingS>{t('disclaimer.terms.5.text')}</HeadingS>

              <HeadingS>{t('disclaimer.terms.6.text')}</HeadingS>
            </VerticalFlex>
          </AppDisclaimerScroller>

          <Checkbox
            value={isChecked}
            label={t('disclaimer.check')}
            onChange={setChecked}
          />
        </VerticalFlex>
      }
      footer={
        <HorizontalFlex
          $gap={Space.V8}
          $justify="space-between"
        >
          <Button
            label={t('common.cancelButton')}
            variant={ButtonVariant.GHOST}
            onClick={() => window.history.go(-1)}
          />

          <Button
            label={t('common.submitButton')}
            disabled={!isChecked}
            onClick={handleSubmit}
          />
        </HorizontalFlex>
      }
    />
  );
};

export default AppDisclaimer;
